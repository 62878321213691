body {
  color: $text-color;
  font: normal 125% / 1.4 $text-font-stack;
}

.indent {
  padding-left: $default-spacing;
}

.text {
  &--big {
    font-size: 1.4em;
  }

  &--small {
    font-size: .85em;
  }

  &--white {
    color: $white;
  }

  &--black {
    color: $black;
  }

  &--main-color {
    color: $main-color;
  }

  &--secondary-color {
    color: $secondary-color;
  }
}
