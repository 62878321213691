.layout {
  background-color: $background-color;
  background-image: url('img/arches.png');
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.fancybox-slide--html .fancybox-content {
  background-color: $background-color;
  background-image: url('img/arches.png');
}

@media screen and (max-width: $breakpoint-medium) {
  .layout {
    padding-bottom: $menu-mobile-height;
  }
}