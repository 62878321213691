.main {
  flex-grow: 1;
  margin: 0 auto;
  max-width: $content-max-width;
  padding: $default-spacing $tiny-spacing;
  position: relative;
  width: 100%;

  &__content {
    padding: $default-spacing 0;
  }
}

@media screen and (max-width: $breakpoint-medium) {
  .main {
    padding: 0 $default-spacing;
  }
}
