.product {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: $default-spacing;
  max-width: 450px;
  position: relative;
  width: calc(16.5% - #{$default-spacing});

  &__aside {
    align-items: center;
    display: inline-flex;
    color: $secondary-color;
    flex-direction: row;
    font-size: .85em;
    justify-content: space-between;
    padding: $small-spacing 0;
    width: 100%;
  }

  &:hover,
  &:focus,
  &:active,
  &--active {
    .product {
      &__image {
        box-shadow: 0 0 $small-spacing $tiny-spacing rgba($secondary-color, .6);
      }

      &__aside {
        text-decoration: underline;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: $default-spacing;
  }

  &__image {
    aspect-ratio: 1/1;
    border-radius: $global-radius;
    box-shadow: 0 0 $small-spacing 0 rgba($secondary-color, .5);
    height: auto;
    max-width: 100%;
    width: 100%;
    transition: box-shadow .25s ease-in-out;
  }

  &__discount {
    color: $mid-grey;
    display: inline-block;
    font-size: .85em;
    position: relative;

    &::after {
      background-color: $red;
      content: ' ';
      height: 2px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: rotate(-15deg);
      transform-origin: center center;
      width: 100%;
    }
  }

  &__status {
    font-size: .85em;
  }

  &__label {
    align-items: center;
    display: inline-flex;
  }
}

@media screen and (max-width: $breakpoint-large) {
  .product {
    width: calc(33% - $default-spacing);
  }
}

@media screen and (max-width: $breakpoint-medium) {
  .product {
    width: calc(50% - $default-spacing);
  }
}
