/// @type Color
$text-color: rgb(34, 34, 34) !default;

/// @type Color
$bordeaux: rgb(66, 1, 23) !default;

/// @type Color
$main-color: rgb(250, 85, 104) !default;

/// @type Color
$secondary-color: $bordeaux !default;

/// @type Color
$blue: rgb(74, 82, 255) !default;

/// @type Color
$white: rgb(255, 255, 255) !default;

/// @type Color
$black: rgb(0, 0, 0) !default;

/// @type Color
$background-color: rgb(244, 227, 192) !default;

/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// @type Color
$mid-grey: rgb(136, 136, 136) !default;

/// @type Color
$dark-grey: rgb(68, 68, 68) !default;

/// @type Color
$green: rgb(0, 200, 0) !default;

/// @type Color
$orange: rgb(255, 101, 0) !default;

/// @type Color
$red: rgb(255, 25, 25) !default;
