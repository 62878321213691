.button {
  align-items: center;
  background-color: $secondary-color;
  border: 1px solid $secondary-color;
  border-radius: $global-radius;
  box-shadow: 0 0 $small-spacing rgba($secondary-color, .5);
  color: $white;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: $tiny-spacing $small-spacing;
  transition: border-color .25s, color .25s, background-color .25s;

  &:hover,
  &:focus,
  &:active,
  &:focus-within {
    background-color: lighten($secondary-color, 10%);
    border-color: lighten($secondary-color, 10%);
  }

  &--big {
    font-size: 1em;
    padding: $small-spacing $default-spacing;
  }

  &--small {
    font-size: .85em;
    padding: $tiny-spacing $small-spacing;
  }

  &--full {
    width: 100%;
  }

  &--bordered {
    background-color: transparent;
    border-color: currentColor;
    color: currentColor;

    &:hover,
    &:focus,
    &:active,
    &:focus-within {
      background-color: transparent;
      border-color: $main-color;
      color: $main-color;
    }
  }

  &--round {
    border-radius: 50%;
    padding: 0 $small-spacing;
  }

  &[disabled] {
    cursor: not-allowed;
    filter: grayscale(1);
    opacity: .5;
    pointer-events: none;
  }

  .fa {
    margin-right: .5em;
  }
}
