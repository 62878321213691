.list {
  list-style: disc;
  padding-left: $default-spacing;

  &--sub {
    list-style: circle;
  }

  &--inline {
    display: inline-flex;
    flex: 1 1 100%;
    justify-content: space-around;
    list-style: none;
    padding: 0;
    width: 100%;
  }
}
