@font-face {
  font-family: 'Hind Siliguri';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/hind-siliguri-v6-latin-regular.eot');
  src: local('Hind Siliguri Regular'), local('HindSiliguri-Regular'),
    url('fonts/hind-siliguri-v6-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/hind-siliguri-v6-latin-regular.woff2') format('woff2'),
    url('fonts/hind-siliguri-v6-latin-regular.woff') format('woff'),
    url('fonts/hind-siliguri-v6-latin-regular.ttf') format('truetype'),
    url('fonts/hind-siliguri-v6-latin-regular.svg#HindSiliguri') format('svg');
}
