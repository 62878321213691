.filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__content {
    max-width: 18.75rem;
    min-width: auto;
    padding-left: $small-spacing;
    padding-right: $small-spacing;
  }

  &__nogroup {
    margin-left: $default-spacing;
    padding: $tiny-spacing $small-spacing;
  }

  &__group {
    border-radius: $global-radius;
    padding: $tiny-spacing $small-spacing;
    transition: box-shadow .5s ease-in-out, background-color .25s ease-in-out;

    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 $small-spacing rgba($secondary-color, .5);
      background-color: rgba($white, 0.25);
      cursor: pointer;
    }
  }

  &__selected-wrapper {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: $small-spacing 0;

    .badge {
      --bs-badge-font-size: 0.65em;
      background-color: rgba($white, 0.5);
      border: 1px solid rgba($bordeaux, .5);
      border-radius: $global-radius;
      color: $bordeaux;
      height: 2.25em;
    }
  }

  &__selected-wrapper,
  &__selected,
  &__fixed {
    display: flex;
  }

  &__selected,
  &__fixed {
    flex-wrap: wrap;
    gap: $small-spacing;
  }

  .form__label {
    white-space: nowrap;
  }

  @media screen and (max-width: $breakpoint-medium) {
    flex-wrap: wrap;
    padding: $default-spacing $small-spacing;

    &__label {
      font-size: 0.85em;
    }
  }
}

