.shop {
  &__layout {
    display: flex;
    flex-direction: column-reverse;
  }

  &__pagination {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 5rem;

    .pagination {
      flex-grow: 1;
    }

    .button {
      flex-grow: 0;
    }
  }

  &__content {
    min-height: 50vh;
  }

  &__content,
  &__aside {
    padding: $small-spacing $default-spacing;
  }
}

@media screen and (max-width: $breakpoint-medium) {
  .shop {
    &__content,
    &__aside {
      padding: $small-spacing 0;
      width: 100%;
    }
  }
}
