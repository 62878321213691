.course {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  padding: 0 2.5em;

  &__chapo {
    margin-bottom: $default-spacing;
    padding: 0 2.5em;
  }

  &__cta {
    align-items: center;
    display: flex;
    flex-direction: column;

    .button {
      margin-top: $default-spacing;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;

    .image {
      margin: $small-spacing 0 $default-spacing;
      width: 100%;
    }
  }

  &__aside {
    padding: $default-spacing 2.5em;
  }

  &__price {
    margin-top: $small-spacing;
  }
}

@media screen and (max-width: $breakpoint-medium) {
  .course {
    margin-bottom: $default-spacing;
    max-width: 100%;
    padding: 0;
    width: 100%;

    &__aside {
      padding: 0;
    }
  }
}
