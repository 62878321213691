$title-color: $secondary-color !default;
$title-color-hover: $main-color !default;

.title {
  color: $title-color;
  font-family: $title-font-stack;
  font-size: 1em;
  margin-bottom: $small-spacing;
  width: 100%;

  &__link {
    &:hover,
    &:focus,
    &:active {
      color: $title-color-hover;
    }
  }

  &--big {
    font-size: 1.5em;
  }

  &--medium {
    font-size: 1.25em;
  }

  &--main {
    font-size: 1.5em;
    margin-bottom: $default-spacing;
    text-align: center;
  }

  & ~ & {
    margin-top: $default-spacing;
  }
}
