.cart {
  &__mini {
    align-items: center;
    background-color: rgba($header-nav-background-color, .95);
    box-shadow: 0 0 $default-spacing rgba($header-nav-background-color, .5);
    color: $main-color;
    display: inline-flex;
    height: 2.6em;
    justify-content: center;
    padding: $tiny-spacing $small-spacing;
    position: absolute;
    right: 0;
    top: 0;

    &:hover,
    &:focus,
    &:active,
    &:focus-within {
      color: $background-color;
    }

    .fa {
      margin-right: .5em;
      padding-bottom: .25em;
    }
  }

  &__element {
    align-items: center;
    border-bottom: 1px solid lighten($mid-grey, 20%);
    display: flex;
    justify-content: space-between;
    margin-bottom: $default-spacing;
    padding-bottom: $default-spacing;

    &--header {
      color: $mid-grey;
      font-size: .75em;
      padding: 0;

      > span {
        text-align: center;
      }

      .cart {
        &__product {
          padding-left: calc(100px + #{$small-spacing});
        }
      }
    }
  }

  &__image {
    margin-right: $small-spacing;
    max-width: 100px;
    min-width: 100px;
  }

  &__product {
    align-items: center;
    display: inline-flex;
    width: 40%;
  }

  &__price {
    display: inline-block;
    text-align: right;
    width: 4rem;

    &--discount {
      margin-bottom: $small-spacing;
    }
  }

  &__quantity {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    width: 160px;
  }

  &__delete {
    width: 2.5rem;
  }

  &__total {
    align-items: flex-end;
    display: flex;
    flex-direction: column;

    .button {
      margin-top: $default-spacing;
    }
  }

  &__update-quantity {
    color: $main-color;
    margin: 0 $default-spacing;

    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      color: $background-color;
    }
  }
}

@media screen and (max-width: $breakpoint-medium) {
  .cart {
    &__mini {
      top: #{-$default-spacing};
    }
  }
}