.checkbox {
  visibility: hidden;
  position: absolute;

  &__label {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    position: relative;

    &:hover,
    &:focus,
    &:active {
      &::after {
        transform: scale(.75, .75);
        transition: transform .25s;
      }
    }

    &::before {
      background-color: $light-grey;
      border: 1px solid $secondary-color;
      border-radius: $global-radius;
      box-shadow: 0 0 .125em rgba($secondary-color, .25) inset;
      content: ' ';
      height: 16px;
      left: -20px;
      position: absolute;
      transition: box-shadow .25s;
      width: 16px;
    }

    &::after {
      background-color: $secondary-color;
      border-radius: $global-radius;
      content: ' ';
      height: 16px;
      left: -20px;
      position: absolute;
      transform: scale(0, 0);
      transition: transform .25s;
      width: 16px;
    }

    &--radio {
      border-radius: 50%;

      &::before,
      &::after {
        border-radius: 50%;
      }
    }
  }

  &:checked {
    + .checkbox__label {
      &::before {
        box-shadow: 0 0 .125em rgba($secondary-color, 0) inset;
        transition: box-shadow .25s;
      }

      &::after {
        transform: scale(1, 1);
        transition: transform .25s;
      }

      &--radio {
        &::before,
        &::after {
          border-radius: 50%;
        }
      }
    }
  }
}
