.section {
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: $default-spacing 0;

  &__sub {
    display: flex;
    flex: 1 1 100%;
    flex-direction: row;
    width: 100%;
  }

  &__content {
    display: flex;
    flex: 1 1 100%;
    justify-content: space-between;
    margin-bottom: $small-spacing;
    padding: 0 $small-spacing;

    &--photos {
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    &--creations {
      .image__wrapper {
        flex-basis: calc(50% - #{$big-spacing});
        max-width: 350px;
        min-width: 250px;
      }
    }

    &--me {
      flex: 1 1 49%;
    }
  }

  &--reversed {
    flex-direction: row-reverse;
  }

  &--nowrap {
    flex-wrap: nowrap;
  }

  &--full {
    width: 100%;
  }
}

@media screen and (max-width: $breakpoint-medium) {
  .section {
    flex-direction: column;

    &__sub,
    &__content {
      flex-direction: column;

      &--home {
        flex-direction: row;
      }

      &--photos {
        flex-direction: row;
        justify-content: center;
      }
    }

    &--reversed {
      flex-direction: column-reverse;
    }
  }
}
