.link {
  text-decoration: underline;

  &--logo {
    font-size: 1.5em;
  }

  &:hover,
  &:focus,
  &:active {
    color: $main-color;
  }
}
