@use "sass:math";
@import 'colors';

/// Regular font family
/// @type List
$text-font-stack: 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$title-font-stack: 'Hind Siliguri', 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

/// @type string
$font-path: '../fonts';

$default-spacing: 1em !default;
$small-spacing: #{math.div($default-spacing, 2)} !default;
$tiny-spacing: #{math.div($default-spacing, 4)} !default;
$big-spacing: #{$default-spacing * 2} !default;

$global-radius: .125em !default;

/// Breakpoints
$breakpoint-small: 500px !default;
$breakpoint-medium: 1000px !default;
$breakpoint-large: 1300px !default;

$content-max-width: 100%;
$menu-mobile-height: 3rem;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;

body {
  --bs-body-bg: rgb(244, 227, 192);
}
