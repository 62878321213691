.sell {
  &__item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    &--shipment {
      padding-left: calc(100px + #{$default-spacing});
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-left: $default-spacing;
  }
}
