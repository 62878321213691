.form {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  justify-content: space-between;

  &--contact {
    margin-left: $big-spacing;
  }

  &__row {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    margin-bottom: $default-spacing;

    &--half {
      max-width: 47.5%;
    }

    &--third {
      max-width: 30%;
    }
  }

  &__buttons {
    display: flex;
    flex: 1 1 100%;
    justify-content: flex-end;

    &--multiple {
      justify-content: space-between;
    }
  }

  &__label {
    font-size: .85em;
  }

  &__field {
    font-size: .75em;

    &--textarea {
      font-size: 1em;
      min-height: 10rem;
      padding: $default-spacing;
    }

    &--select {
      margin-bottom: $default-spacing;
    }
  }

  &__error {
    color: $red;
    list-style: disc;
    margin-bottom: $small-spacing;
    margin-left: $default-spacing;
  }
}

@media screen and (max-width: $breakpoint-medium) {
  .form {
    margin: 0;

    &__row {
      &--half {
        max-width: 100%;
      }
    }
  }
}
