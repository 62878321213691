.flash {
  background-color: rgba($mid-grey, .5);
  border-bottom: 1px solid $black;
  display: flex;
  width: 100%;

  a {
    text-decoration: underline;
  }

  &--success {
    background-color: rgba($secondary-color, .85);
    border-color: darken($secondary-color, 20%);
    color: $background-color;
  }

  &--error {
    background-color: $main-color;
    border-color: darken($main-color, 20%);
    color: $black;
  }

  &__content {
    align-items: center;
    display: inline-flex;
    flex-grow: 1;
    justify-content: center;
    min-height: 2.6em;
    padding: $small-spacing $default-spacing;
  }

  &__close {
    align-items: center;
    display: inline-flex;
    flex-grow: 0;
    height: 2.6em;
    justify-content: center;
    justify-self: flex-end;
    width: 2.6em;
  }
}
