.image {
  border-radius: $global-radius;
  box-shadow: 0 0 $small-spacing rgba($secondary-color, .5);
  height: auto;
  width: 100%;

  &__wrapper {
    margin-right: $default-spacing;

    &--me {
      flex-basis: 60%;
      max-width: 25vw;
    }
  }
}
