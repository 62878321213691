.shop-product {
  column-gap: $default-spacing;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  width: 100%;

  &__media {
    display: flex;
    flex-direction: row;
    max-height: 500px;
    max-width: 50%;
    order: -1;

    &-link {
      box-shadow: 0 0 $small-spacing rgba($secondary-color, .5);
      display: inline-flex;
    }
  }

  &__thumbnails {
    display: flex;
    flex: 0 0 100px;
    flex-direction: column;
    margin: 0;
    padding: $small-spacing;
    width: 100px;
  }

  &__content {
    flex-grow: 1;
  }

  &__description {
    margin-bottom: $default-spacing;
  }

  &__status {
    margin-right: $small-spacing;
  }

  &__price {
    align-items: center;
    display: inline-flex;
    font-size: 1.4em;

    &-discount {
      margin-left: $small-spacing;
    }

    &-wrapper {
      align-items: center;
      display: inline-flex;
      flex-direction: row;
      margin-bottom: $default-spacing;
    }
  }

  &__shipping {
    align-items: center;
    display: inline-flex;
    font-size: 1rem;
    margin-left: $small-spacing;
  }

  &__images {
    border-radius: $global-radius;
    height: auto;
    max-width: calc(100% - 100px);
    padding: $small-spacing;
  }

  &__group {
    align-items: center;
    border-bottom: 1px solid rgba($mid-grey, .25);
    display: flex;
    flex-wrap: wrap;
    font-size: .9em;
    margin: $tiny-spacing 0;

    &-name {
      display: inline-flex;
      font-size: 1.15em;
      justify-content: flex-start;
      padding: $tiny-spacing 0;
      width: 125px;
    }
  }

  &__tag {
    color: $secondary-color;
    display: inline-flex;
    margin-left: $tiny-spacing;

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }

  &__cart {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: $default-spacing;
    max-width: 100%;

    &-row {
      margin-bottom: 0;
      margin-right: $big-spacing;
    }

    .button {
      max-height: 2.5em;
    }
  }
}

@media screen and (max-width: $breakpoint-medium) {
  .shop-product {
    flex-direction: column;

    &__media {
      height: auto;
      max-width: 100%;
      width: 100%;
    }

    &__images {
      max-width: 100%;
    }

    &__thumbnails {
      display: none;
    }

    &__content {
      margin-top: $default-spacing;
    }

    &__cart {
      flex-direction: column;

      &-row {
        margin-bottom: $default-spacing;
        margin-right: 0;
      }
    }
  }
}
