$footer-nav-background-color: $header-nav-background-color !default;

.footer {
  align-items: center;
  box-shadow: 0 0 $default-spacing rgba($footer-nav-background-color, .5);
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  font-size: .75em;
  justify-content: center;

  &__content {
    align-items: center;
    background-color: rgba($footer-nav-background-color, .95);
    color: $main-color;
    display: flex;
    justify-content: center;
    padding: $default-spacing $small-spacing;
    width: 100%;
  }
}
