.panel {
  background-color: $secondary-color;
  border-radius: $global-radius;
  box-shadow: 0 0 $small-spacing rgba($secondary-color, .5);
  color: $background-color;
  margin: 0 $small-spacing;
  min-width: 25rem;
  padding: $default-spacing;
  width: 30%;

  .title {
    color: currentColor;
  }
}

@media screen and (max-width: $breakpoint-medium) {
  .panel {
    margin: 0 0 $default-spacing;
    min-width: unset;
    width: 100%;
  }
}
