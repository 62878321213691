.shipping {
  margin: auto;
  text-align: center;
  width: 80%;

  td,
  th {
    border: 1px solid;
    padding: $small-spacing $default-spacing;
    vertical-align: middle;
  }

  th {
    font-size: 1.125em;
    font-weight: bold;
  }
}
