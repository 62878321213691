.menu {
  align-items: center;
  display: flex;
  font-family: $title-font-stack;
  height: 100%;
  justify-content: space-between;
  max-width: $content-max-width;
  width: 100%;

  &__link {
    align-items: center;
    display: inline-flex;
    flex: 1 1 100%;
    height: 100%;
    justify-content: center;
    padding: $small-spacing;
    text-align: center;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: $background-color;
      text-decoration: underline;
    }
  }

  &__item {
    align-items: center;
    display: inline-flex;
    flex: 1 1 100%;
    height: 100%;
    justify-content: center;

    &--has-children {
      position: relative;

      .menu {
        display: none;
        flex-direction: column;
        justify-content: flex-start;
        position: absolute;
        top: 3rem;
        z-index: 3;

        &__item {
          background-color: rgba($secondary-color, .95);
          border-top: 1px solid;
          min-width: 100%;
        }
      }

      &:hover,
      &:focus,
      &:focus-within {
        .menu {
          display: flex;
        }
      }
    }

    &--current, {
      .menu__link {
        color: $background-color;
      }
    }

    &--current-ancestor {
      > .menu__link {
        color: $background-color;
      }
    }
  }
}
