.pagination {
  display: flex;
  justify-content: center;
  margin: $default-spacing 0;

  &__element {
    margin: 0 $tiny-spacing;
    padding: $tiny-spacing;
    transition: background-color .375s, color .375s, box-shadow .375s;

    &--current {
      background-color: $secondary-color;
      color: $background-color;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $secondary-color;
      box-shadow: 0 0 $default-spacing rgba($header-nav-background-color, .5);
      color: $background-color;
      text-decoration: underline;
    }
  }
}
