.breadcrumbs {
  border-bottom: 1px solid lighten($dark-grey, 20%);
  color: $dark-grey;
  display: flex;
  flex: 0 1 100%;
  font-size: .85em;
  padding: $tiny-spacing 0;

  &__list {
    display: flex;
    justify-content: flex-start;
  }

  &__element {
    &:not(:last-of-type) {
      &::after {
        content: '>';
        margin: 0 $tiny-spacing;
      }
    }
  }
}
