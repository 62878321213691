//
// Set up a decent box model on the root element
//
html {
  box-sizing: border-box;
}

//
// Make all elements from the DOM inherit from the parent box-sizing
// Since `*` has a specificity of 0, it does not override the `html` value
// making all elements inheriting from the root box-sizing value
// See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
//
*,
*::before,
*::after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $text-font-stack;
  line-height: 1;
  margin-bottom: 0;
}

//
// Basic styles for links
//
a {
  color: currentColor;
  text-decoration: none;
  transition: color .15s, text-shadow .15s, background .15s;
}

a,
button {
  &:active,
  &:focus {
    outline: 0;
  }
}

p,
li {
  line-height: 1.4;
}

ul {
  margin-left: 0;
}
