$header-nav-background-color: $secondary-color !default;
$header-logo-background-color: #f1b576 !default;
$header-title-color: #001f45 !default;

.header {
  align-items: center;
  box-shadow: 0 0 $default-spacing rgba($header-nav-background-color, .5);
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;

  &__logo {
    align-items: center;
    background-color: rgba($header-logo-background-color, .5);
    background-image: url('img/logo_inv.png'), url('img/logo.png');
    background-position: left -20rem bottom -11rem, right -20rem bottom -11rem;
    background-repeat: no-repeat, no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 17.5rem;
    padding: 1em;
    width: 100%;
  }

  &__title {
    color: $header-title-color;
    font-weight: bold;

    .header__image {
      max-width: 450px;
    }

    &--subtitle {
      font-size: 2em;
      max-width: 450px;
      text-align: right;
      width: 100%;

      .header__image {
        max-height: 1em;
      }
    }
  }

  &__content {
    align-items: center;
    background-color: rgba($header-nav-background-color, .95);
    color: $main-color;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

@media screen and (max-width: $breakpoint-medium) {
  .header {
    margin-bottom: $default-spacing;

    &__logo {
      background-image: none;
      min-height: unset;
    }

    &__title {
      .header__image {
        max-width: 100%;
      }
    }

    &__content {
      bottom: 0;
      box-shadow: 0 0 $default-spacing rgba($header-nav-background-color, .5);
      height: $menu-mobile-height;
      font-size: .75em;
      position: fixed;
      z-index: 2;
    }
  }
}
