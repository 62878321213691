.column {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  margin-top: $default-spacing;
  width: 100%;

  &--half {
    max-width: 50%;
  }
}

@media screen and (max-width: $breakpoint-medium) {
  .column {
    &--half {
      max-width: 100%;
    }
  }
}
